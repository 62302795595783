<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3  {{ $t('myadmincenters.admcenter')}}
                    
                    div.text-center.mx-auto
                        router-link.btn.btn-outline-dark(to="/pages/centers") {{ $t('btns.btncreatecenter')}}
                            svg(version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve")
                                path(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" d="M5.3,6.8l10.2,0c0.9,0,1.7,0.8,1.7,1.7v10.2c0,0.9-0.8,1.7-1.7,1.7l-10.2,0c-0.9,0-1.7-0.8-1.7-1.7l0-10.2C3.6,7.5,4.3,6.8,5.3,6.8z")
                                rect(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x="7.8" y="11" width="5.1" height="5.1")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="7.8" y1="4.2" x2="7.8" y2="6.8")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="13" y1="4.2" x2="13" y2="6.8")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="7.8" y1="20.4" x2="7.8" y2="23")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="13" y1="20.4" x2="13" y2="23")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="17.2" y1="11" x2="19.8" y2="11")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="17.2" y1="15.3" x2="19.8" y2="15.3")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="1" y1="11" x2="3.6" y2="11")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="1" y1="15.3" x2="3.6" y2="15.3")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="20.5" y1="1" x2="20.5" y2="6.3")
                                line(style="fill:none;stroke:#ff5000;stroke-linecap:round;stroke-linejoin:round;" x1="17.9" y1="3.6" x2="23.1" y2="3.6")
                    
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                              li.breadcrumb-item
                                router-link(to="/pages/home") {{ $t('headers.dashboard')}}
                              li.breadcrumb-item
                                router-link(to="/pages/dashboardCenter") {{ $t('headers.centers')}}
                              li.breadcrumb-item.active(aria-current="page") {{ $t('myadmincenters.admcenter')}}
                              
                div.row.mt-5.pb-5
                    depending-centers-component
                       

</template>

<script>
/* eslint-disable */
import DependingCentersComponent from '@/components/Admin/DependingCentersComponent'
export default {
    name: 'MyAdminCenters',
    components: {
        DependingCentersComponent
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {

    }
}
</script>
